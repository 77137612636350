// colors
$black: #000;
$black-light3: #555;
$black-light2: #1a1a1a;
$black-light: #333;
$gray-light: #e6e6e6;
$gray: #d9d9d9;
$gray-dark: #ccc;
$white: #fff;
$white-dark: #ddd;
$white-dark2: #f5f5f5;
$white-dark3: #eaeaea;
$yellow: #5cabd9;
$green: #53a674;
$blue: #293961;
$blue-light: #4a4f75;
$blue-light2: #44496b;
$blue-dark: #465ca6;
$orange: #b06425;
$purple: #891e73;
$cyan: #539fa6;
$transparent: rgba(255, 255, 255, 0);
$black-transparent85: rgba(0, 0, 0, .85);
$black-transparent60: rgba(0, 0, 0, .6);
$black-transparent50: rgba(26, 26, 26, .5);
$black-transparent40: rgba(0, 0, 0, .4);
$black-transparent30: rgba(0, 0, 0, .3);
$black-transparent15: rgba(0, 0, 0, .15);
$black-light2-transparent80: rgba(26, 26, 26, .8);
$white-transparent: rgba(236, 240, 241, .2);

// cubic beziers
$bezier1: cubic-bezier(.81, .09, .17, .85);
$bezier2: cubic-bezier(.09, .7, .5, .37);

// media breaks
$break-xxlarge: 1440px;
$break-xlarge: 1199px;
$break-large: 991px;
$break-medium: 767px;
$break-small: 575px;
$break-xsmall: 480px;

$menu-shadow: drop-shadow(1px 1px 0 $black-transparent30);
$no-shadow: drop-shadow(1px 1px 0 transparent);

%zero-margin-padding {
  margin: 0;
  padding: 0;
}

%clear {
  clear: both;
  float: none;
}
